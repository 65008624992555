import React from "react"
import "./Dashboard.less"

/**
 * 仪表盘
 * @constructor
 */
const Dashboard: React.FC = () => {
  // const dispatch = useDispatch()
  //
  // // 每隔 5 秒钟更新数据
  // useEffect(() => {
  //   dispatch.dashboard.updateDate()
  //   const timer = setInterval(() => {
  //     // 模拟异步请求数据
  //     dispatch.dashboard.updateDate()
  //   }, 10000)
  //
  //   return () => clearInterval(timer)
  // }, [dispatch.dashboard])

  return (
    <div className="Dashboard">
      仪表盘开发中
    </div>
  )
}

export default Dashboard
