import React from "react"
import "./Refund.less"

const Refund: React.FC = () => {

  return (
    <div className="Refund">
      退款记录开发中
    </div>
  )
}

export default Refund
