import React, {useEffect, useState} from "react"
import {Button, Drawer, Form, Input, Layout, message, Modal, Space, Table} from "antd"
import type {ColumnsType} from "antd/es/table"
import {useDispatch, useSelector} from "react-redux"

const {Search} = Input

interface DataType {
  key: string
  roleType: string
  name: string
  nickName: string
  mobile: string
  memo: string
  modified: string
  created: string
}

const UserModuleTable: React.FC = () => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState("")
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [newOpen, setNewOpen] = useState(false)
  const [newForm] = Form.useForm()

  const [modifyOpen, setModifyOpen] = useState(false)
  const [modifyForm] = Form.useForm()

  const userModule = useSelector((state: any) => state.userModule)
  const dispatch = useDispatch()

  const [messageApi, contextMessageHolder] = message.useMessage()

  const columns: ColumnsType<DataType> = [
    {
      title: "用户类型",
      dataIndex: "roleType",
      key: "roleType",
      render: (text): string => (text === "SUPER" ? "超级管理员" : "普通管理员"),
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "昵称",
      dataIndex: "nickName",
      key: "nickName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "修改时间",
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "创建时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => {
        if (record.roleType === "SUPER") return null
        return (
          <>
            <Button type="link" size="small" onClick={() => handleModify(record)}>
              修改
            </Button>
            <Button type="link" onClick={() => handleDelete(record)}>
              删除
            </Button>
          </>
        )
      },
    },
  ]

  const onSearch = (value: string) => {
    let request = userModule.request
    request.keyword = value
    request.pageNum = 1
    dispatch.userModule.setData({request})
  }

  const handleModify = (record: any) => {
    setModifyOpen(true)
    modifyForm.setFieldsValue(record)
  }

  const handleDelete = (record: any) => {
    setDeleteId(record.id)
    setDeleteOpen(true)
  }

  const handleDeleteOk = () => {
    setConfirmLoading(true)
    dispatch.userModule.delete({id: deleteId}).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("删除成功")
        dispatch.userModule.page()
        setDeleteOpen(false)
      } else {
        messageApi.error(res.msg)
      }
      setConfirmLoading(false)
    })
  }

  const handleDeleteCancel = () => {
    setDeleteOpen(false)
  }

  const onNewClose = () => {
    setNewOpen(false)
  }

  const onNewSave = () => {
    newForm.submit()
  }

  const onNewFinish = (values: any) => {
    dispatch.userModule.add(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("新增成功")
        dispatch.userModule.page()
        setNewOpen(false)
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  const onModifyClose = () => {
    setModifyOpen(false)
  }

  const onModifySave = () => {
    modifyForm.submit()
  }

  const onModifyFinish = (values: any) => {
    dispatch.userModule.modify(values).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("修改成功")
        setModifyOpen(false)
        dispatch.userModule.page()
      } else {
        messageApi.error(res.msg)
      }
    })
  }

  useEffect(() => {
    dispatch.userModule.page()
  }, [userModule.request.keyword, userModule.request.pageNum, dispatch.userModule])

  return (
    <>
      {contextMessageHolder}
      <Modal
        title="特别提醒"
        open={deleteOpen}
        onOk={handleDeleteOk}
        confirmLoading={confirmLoading}
        onCancel={handleDeleteCancel}
        okText="确认"
        cancelText="取消"
      >
        <p>删除不可恢复，确认要删除用户吗？</p>
      </Modal>
      <Layout>
        <Space>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
          <Button type="primary" onClick={() => setNewOpen(true)} style={{backgroundColor: "#339933"}}>
            新建
          </Button>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={userModule.data.list}
            pagination={{
              pageSize: userModule.data.pageSize, // 每页显示的条数
              total: userModule.data.total, // 数据总条数
              onChange: (page, pageSize) => {
                let request = {...userModule.request, pageNum: page, pageSize}
                dispatch.userModule.setData({request})
              },
            }}
          />
        </Layout>
      </Layout>
      <Drawer
        title="新建用户"
        width={500}
        onClose={onNewClose}
        open={newOpen}
        styles={{body: {paddingBottom: 80}}}
        extra={
          <Space>
            <Button onClick={onNewClose}>取消</Button>
            <Button onClick={onNewSave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={newForm} onFinish={onNewFinish} layout="vertical">

          <Form.Item name="mobile" label="手机号" rules={[{required: true, message: "输入手机号"}]}>
            <Input placeholder="输入手机号"/>
          </Form.Item>

          <Form.Item
            name="memo"
            label="备注"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        title="修改用户"
        width={500}
        onClose={onModifyClose}
        open={modifyOpen}
        styles={{body: {paddingBottom: 80}}}
        extra={
          <Space>
            <Button onClick={onModifyClose}>取消</Button>
            <Button onClick={onModifySave} type="primary">
              保存
            </Button>
          </Space>
        }
      >
        <Form form={modifyForm} onFinish={onModifyFinish} layout="vertical">
          <Form.Item name="id" label="编号" hidden>
            <Input placeholder="输入编号"/>
          </Form.Item>

          <Form.Item name="mobile" label="手机号" rules={[{required: true, message: "输入手机号"}]}>
            <Input placeholder="输入手机号"/>
          </Form.Item>

          <Form.Item
            name="memo"
            label="备注"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="输入备注"/>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default UserModuleTable
