import React from "react"
import "./Form.less"

const Form: React.FC = () => {

  return (
    <div className="Form">
      收款码开发中
    </div>
  )
}

export default Form
