import React, {useCallback, useEffect} from "react"
import logo from "../../images/logo.png"
import global from "../../global"
import "./Login.less"
import {Layout, message, QRCode} from "antd"
import {useDispatch, useSelector} from "react-redux"
import {Dispatch} from "redux"
import {useNavigate} from "react-router-dom"

const {Footer} = Layout

const Login: React.FC = () => {
  let qrCodeLogin = useSelector((state: any) => state.qrCodeLogin)
  let dispatch = useDispatch<Dispatch>()
  let navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()

  const onRefresh = useCallback(() => {
    dispatch.qrCodeLogin.setData({status: 'loading'})
    dispatch.qrCodeLogin.refresh().then((res: any) => {
      if (res.code !== 0) return;
      dispatch.qrCodeLogin.setData({status: 'active', tip: '使用微信识别二维码，授权登录'})
    });
  }, [dispatch.qrCodeLogin])

  useEffect(() => {
    onRefresh();
  }, [onRefresh])

  //启动
  useEffect(() => {

    //每隔 3 秒钟更新数据
    const timer = setInterval((payload, state) => {
      if (qrCodeLogin.code === '') return;
      if (qrCodeLogin.status === 'expired') return;//过期了不检查

      // 模拟异步请求数据
      dispatch.qrCodeLogin.status().then((res: any) => {
        if (res.code !== 0) return;

        //去登录
        if (res.data.loginStatus === 'ALLOWED') {
          let data = {
            code: qrCodeLogin.code,
            secret: res.data.secret,
          }
          dispatch.session.login(data, state).then((res: any) => {
            if (res.code !== 0) {
              messageApi.error(res.msg);
            } else {
              messageApi.success("登录成功");
              navigate('/home/dashboard', {replace: true});
            }
          });
        }
        //过期
        if (res.data.loginStatus === 'EXPIRED') {
          dispatch.qrCodeLogin.setData({tip: '二维码已过期，请刷新重新生成', status: 'expired'})
        }
        //已扫描
        if (res.data.loginStatus === 'SCANNED') {
          dispatch.qrCodeLogin.setData({tip: '已经扫码，请在手机上确认授权登录', status: 'loading'})
        }
      });

    }, 3000);

    return () => clearInterval(timer);
  }, [dispatch.qrCodeLogin, dispatch.session, navigate, onRefresh, qrCodeLogin.code, qrCodeLogin.status, messageApi]);

  return (
    <div className="Login">
      {contextHolder}
      <Layout className="header">
        <img className="logo" src={logo} alt=""/>
        <div className="title">{global.name}</div>
      </Layout>
      <Layout className="body">
        <div className="qr-code">

          <QRCode
            status={qrCodeLogin.status} // 二维码的状态
            onRefresh={onRefresh} // 点击刷新的回调
            value={qrCodeLogin.url + '/' + qrCodeLogin.code}// 生成二维码的内容
            size={260} // 二维码的大小
            imageSettings={{ // 中间有图片logo
              src: logo,
              height: 60,
              width: 60,
              excavate: true
            }}
          />

        </div>

        <div className="tip-item">
          {qrCodeLogin.tip}
        </div>
      </Layout>
      <Layout>
        <Footer style={{textAlign: "center"}}>{global.company} ©2023</Footer>
      </Layout>
    </div>
  )
}

export default Login
