import React from "react"
import "./Order.less"

const Order: React.FC = () => {

  return (
    <div className="Order">
      收款订单开发中
    </div>
  )
}

export default Order
