import React, {lazy, Suspense, useEffect} from "react"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom"
import Login from "./pages/login/Login"
import Test from "./pages/test/Test"
import Error from "./pages/error/Error"
import Dashboard from "./pages/dashboard/Dashboard"
import {useSelector} from "react-redux"
import {RootState} from "./store"
import UserModule from "./pages/userModule/UserModule";
import global from "./global";
import Form from "./pages/form/Form";
import Order from "./pages/order/Order";
import Pay from "./pages/pay/Pay";
import Refund from "./pages/refund/Refund";

const Home = lazy(() => import("./pages/home/Home"))

/**
 * 应用路由
 */
const AppRouter = () => {
  let session = useSelector((state: RootState) => state.session)

  useEffect(() => {
    document.title = global.name;
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home/dashboard"/>}/>
        <Route path="/test" element={<Test/>}/>
        <Route path="/error" element={<Error/>}/>
        <Route path="/login" element={<Login/>}/>

        {/* 后台 */}
        <Route
          path="/home"
          element={
            session.isLogin ? (
              <Suspense>
                <Home/>
              </Suspense>
            ) : (
              <Navigate to="/login"/>
            )
          }
        >
          <Route path="/home/dashboard" element={<Dashboard/>}/>
          <Route path="/home/userModule" element={<UserModule/>}/>
          <Route path="/home/form" element={<Form/>}/>
          <Route path="/home/order" element={<Order/>}/>
          <Route path="/home/pay" element={<Pay/>}/>
          <Route path="/home/refund" element={<Refund/>}/>
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter
