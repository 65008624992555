import React from "react"
import "./Pay.less"

const Pay: React.FC = () => {

  return (
    <div className="Pay">
      付款记录开发中
    </div>
  )
}

export default Pay
