import {Models} from "@rematch/core"
import {session} from "./session"
import {dashboard} from "./dashboard";
import {qrCodeLogin} from "./qrCodeLogin";
import {userModule} from "./userModule";
import {file} from "./file";

export interface RootModel extends Models<RootModel> {
  dashboard: typeof dashboard
  file: typeof file
  qrCodeLogin: typeof qrCodeLogin
  session: typeof session
  userModule: typeof userModule
}

export const models: RootModel = {
  dashboard,
  file,
  session,
  qrCodeLogin,
  userModule,
}
