/**
 * 全局配置
 */
const global = {
  name: "握手",
  nameEn: "woshou",
  company: "上海顶讯网络科技有限公司",

  //版本
  version: "0.1",

  //环境，通过请求地址自动判断是否开发环境
  env: window.location.href.startsWith("http://localhost") ? "dev" : "pro",

  //接口服务器
  apiServers: [
    {
      env: "dev",
      // url: "/dev-api",  //开发环境下使用代理
      url: "/pro-api",
    },
    {
      env: "pro",
      url: "https://manager.woshou.lianhezuqiu.cn/api",
    },
  ],

  //获取接口地址
  getApiServer(): string {
    let url: string = ""
    this.apiServers.forEach((item: any): void => {
      if (item.env === this.env) url = item.url
    })
    return url
  },

  //获取令牌
  getSessionKey(): string {
    //dev环境下不需要登录
    if (this.env === "dev") {
      // return "26db485149c2419982a057bbdb3eeba32b1107002e6b782e91457a1503fe965d"
    }

    let sessionKey: string | null = localStorage.getItem("sessionKey");
    if (sessionKey === 'undefined' || sessionKey == null) {
      return ""
    }

    let expireTime: string | null = localStorage.getItem("expireTime")
    if (expireTime != null && new Date().getTime() < Date.parse(expireTime.replace(/-/g, "/"))) {
      return sessionKey
    }

    return ""
  },

  //保存令牌
  saveSessionKey(sessionKey: string, expireTime: string): void {
    localStorage.setItem("sessionKey", sessionKey)
    localStorage.setItem("expireTime", expireTime)
  },

  //清空令牌
  clearSessionKey(): void {
    localStorage.removeItem("expireTime")
    localStorage.removeItem("sessionKey")
  },

  //上传头部
  getUploadHeaders() {
    // "Content-Type": "multipart/form-data;charset=utf-8",
    return {"Session-Key": global.getSessionKey()}
  },

  //获取文件地址
  getFileOpenUrl(data: any) {
    if (data !== null)
      return this.getApiServer() + '/pub/v1/file/open?fileKey=' + encodeURI(data.fileKey) + '&accessKey=' + data.accessKey
    return ''
  }
}

export default global
